import { inject }                         from 'aurelia-framework';
import { PLATFORM }                       from 'aurelia-pal';
import { BaseFormViewModel }              from 'base-form-view-model';
import { FormSchema }                     from 'modules/management/dynamic-fields/part-zone-applications/form-schema';
import { PartZoneApplicationsRepository } from 'modules/management/dynamic-fields/part-zone-applications/services/repository';
import { AppContainer }                   from 'resources/services/app-container';

@inject(AppContainer, PartZoneApplicationsRepository, FormSchema)
export class CreatePartZoneApplication extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    formId      = 'management.dynamic-fields.part-zone-applications.creation-form';

    alert  = {};
    model  = {};
    schema = {};

    create = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/button-form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.dynamic-fields.part-zone-applications.manage',
            'management.dynamic-fields.part-zone-applications.create',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.nextOrderNumber = await this.fetchNextOrderNumber();

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);
        this.initialModel = this.formSchema.model(this.nextOrderNumber);
        this.model        = this.formSchema.model(this.nextOrderNumber);
    }

    /**
     * Fetches the next order number
     *
     * @returns {Promise}
     */
    async fetchNextOrderNumber() {
        return await this.repository.fetchNextOrderNumber();
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.model)
            .then((response) => {
                this.handleResponse(response, 'management.dynamic-fields.part-zone-applications.index');
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

}
